/*
 Contact Action
*/


import React, { Component } from 'react';  
import ReactDOM from "react-dom";
import { Link } from 'react-router-dom';

import './ContactAction.scss';

// componnents

import NumberItem from '../NumberItem';
import MinySubHeading from '../MinySubHeading';


export default class ContactAction extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

   
    };

   

  }


  render(){

    const {

    } = this.props;




   return (
    
      <div className="contact-action-wrapper animate-in">
    
        <Link to="/contact">
        <div className="contact-action-flex-wrapper">
          <div>
           <NumberItem 
             number="04"
             position="absolute"
             margin="64px 0px 0px 50px"
            
             />
               <span className='miny-sub-heading-wrapper initial-transition-header animate-in'>
                <span></span><span>get in touch</span>
              </span>
              <span className="contact-link">Contact</span>
              <span className="hover-under"></span>
            </div>
          </div>
        </Link>
      </div>
        
    );
  }
}


