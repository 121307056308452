/*
 Body Content Component
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";

import './BodyContent.scss';

//plugins
import lax from 'lax.js';
import NumberItem from '../NumberItem';
import MinySubHeading from '../MinySubHeading';

// style variables
import variables from '../../config_var/config_var.js';

export default class BodyContent extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

   
    };

   

  }


  scrollMoreDown = () => {

    let windowHeight = window.innerHeight;
    windowHeight = windowHeight - 100;
    window.scrollTo({
        top: windowHeight,
        left: 100,
        behavior: 'smooth'
      });

    };


  render(){

    const {

      subinfo
      
    } = this.props;

    const secondary_color = variables.secondary_base_color;



   return (


      <div className="body-content-wrapper animate-in" id="wrapper">
        <div class="animate-in">
        <NumberItem 
        number="02"
        position="absolute"
        left="-75px"
        color={variables.primary_font_color}
        top="133px"
        />
          <h6
            className="home-sub-row-heading"
            >
            Your website is your digital image/footprint online. First impressions do matter and presenting yourself, your products and services to your target market is key. 

        </h6>
        <MinySubHeading
        heading="what I do"
        width="144px"
        left="-66px"
        />
         <p
           style={{width:'90%',marginRight:'0',paddingRight:'0',textAlign:'left'}}
            >
            I specialise in <strong>high-end</strong> bespoke websites and <strong>fully managed</strong> hosting operations with an emphasis on increasing user engagement, scalability and reliability.<br/><br/> I'm a <strong>creative full-stack</strong> developer - <strong>Mathew Bowyer</strong>
            <br/><br/>
        </p>

        </div>
        <div>
         <div className="scroll lax" data-lax-opacity="0 1, (document.body.scrollHeight*0.5) 0">
              <button onClick={this.scrollMoreDown.bind(this)}>Scroll</button>
          </div>
          <Link to="/work/mesim">
            <span className="filter"></span>
            <span className="init-filter"></span>
            <span className="filter-hover" style={{backgroundColor:'#8353fd'}}></span>

             <span>
                <p className="date">01/04/2023</p>
                <h5>Mesim</h5>
                <span><p>Always stay connected wherever you go.</p></span>
             </span>
          </Link>
        </div>
      </div>
        
    );
  }
}


