/*
 Miny Sub Heading Component
*/


import React, { Component } from 'react';  
import ReactDOM from "react-dom";

import './MinySubHeading.scss';


export default class MinySubHeading extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

     
    };

   

  }




  render(){


    const {

      heading,
      className,
      position,
      margin,
      left,
      width
      
    } = this.props;




   return (
   
      <span className='miny-sub-heading-wrapper initial-transition-header animate-in' style={{position:position,margin:margin,left:left,width:width}}>
        <span></span><span>{heading}</span>
      </span>
        
    );
  }
}


