/*
 Home Header Component
*/


import React, { Component } from 'react';  
import ReactDOM from "react-dom";

import { AnimatedText } from "./../AnimatedText";
import './HomeHeader.scss';

/* company logo */

import CREOBLACK from '../../media/images/creoblack-v3.svg';
import Tilt from 'react-tilt';
import lax from 'lax.js';
import Lottie from 'react-lottie';

import animationData from './home.json'


export default class HomeHeader extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };



  }

   componentDidMount() {


    let words = ["social", "design", "digital"];
    let i = 0;
    let text = "This text changes";
    let changer = document.getElementById("changer");

    let $lineone = document.getElementById('create-awareness');
    let $linetwo = document.getElementById('through-positive');
    let $linethree = document.getElementById('digital');
    let $linefour = document.getElementById('footprint');
    let $linefive = document.getElementById('home-head-sub-p');

    $lineone.innerHTML = $lineone.innerHTML.replace(/./g, "<span class='animated-text'>$&</span>").replace(/\s/g, " ");
    $linetwo.innerHTML = $linetwo.innerHTML.replace(/./g, "<span class='animated-text'>$&</span>").replace(/\s/g, " ");
    $linethree.innerHTML = $linethree.innerHTML.replace(/./g, "<span class='animated-text'>$&</span>").replace(/\s/g, " ");
    $linefour.innerHTML = $linefour.innerHTML.replace(/./g, "<span class='animated-text'>$&</span>").replace(/\s/g, " ");
    $linefive.innerHTML = $linefive.innerHTML.replace(/./g, "<span class='animated-text-para'>$&</span>").replace(/\s/g, " ");


    function getChangedText() {
      i = (i + 1) % words.length;
      return text.replace(/This text changes/, words[i]);
    }
    function changeText() {
        var txt = getChangedText();
        var d = changer;
        d.className = "fadeOut";
        setTimeout(function(){
         d.className = "changer-initial";
        changer.innerHTML = txt;
    }, 1000);
    }
    if(changer!=null){
     setInterval(function(){ changeText(); }, 3000);
    }


    lax.setup() // init

    const updateLax = () => {
      lax.update(window.scrollY)
      window.requestAnimationFrame(updateLax)
    }

    window.requestAnimationFrame(updateLax)
    

  }



  render(){

    const {

      subinfo
      
    } = this.props;

    const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };


   return (
   
      <div className="home-header-wrapper Tilt-inner">
      <div class="sub-one">
      <span className="background-title">GRAPECAKE</span>
        <div className="transition-wrapper">
        
        </div>
    

          <div className="animatedTextHeader">

            <span className="number"><span className="line"></span><span className="the-number-two">HOME</span></span>
            <h2 className="intro-title">
              <span className="title-in">
                <span id="create-awareness">Create awareness</span>
              </span><br/> 
              <span className="title-in">
                <span id="through-positive">through a positive</span> 
              </span><br/>
              <span class="changer-wrapper">
              <span id="changer" className="changer-initial">
                <span id="digital">digital</span>
              </span>
              </span>
              <br/> 
              <span className="title-in">
                <span id="footprint">footprint.</span>
                </span>
              </h2>
            <p>
              <span className="title-in"><span id="home-head-sub-p">I create digital solutions for a digital world.</span></span>
            </p>
          </div>

           </div>
           <div class="sub-two">
           <Lottie options={defaultOptions}
              height='100%'
              width='100%'/>
           </div>
        </div>
        
    );
  }
}


