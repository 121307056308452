/*
 Number Item Component
*/


import React, { Component } from 'react';  
import ReactDOM from "react-dom";

import './NumberItem.scss';




export default class NumberItem extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

   
    };

   

  }


  render(){

    const {

      number,
      color,
      borderTop,
      margin,
      position,
      left,
      top
    } = this.props;




   return (
   
      <span className="number-item-wrapper" style={{borderTop:borderTop,margin:margin,position:position,left:left,top:top}}>
        <span style={{color:color}}>No</span>
        <span style={{color:color}}>{number}</span>
      </span>
        
    );
  }
}


