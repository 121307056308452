/*
 Case studies content
*/


import React, {Component} from 'react';
import TransitionHeader from './../TransitionHeader';
import {Link} from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

import MinySubHeading from '../MinySubHeading';
import ContactAction from './../ContactAction';
import FooterActionNavigation from './../FooterActionNavigation';
import NumberItem from './../NumberItem';

import './CaseStudiesContent.scss';


// Articles.
import articles from '../../articles/articles.js';

export default class CaseStudiesContent extends React.Component {


    constructor(props) {
        super(props);

        // State.

        this.state = {};


    }

    componentDidMount() {
        window.scrollTo(0, 0);

        const header = document.getElementById('header-view');
        const hamburger = document.getElementById('menu-button');

        header.classList.remove('header-view-show');
        hamburger.classList.remove('is-active');

    }


    render() {


        return (
            <div className="case-studies-content-wrapper animate-in">
      <span className='miny-sub-heading-wrapper initial-transition-header animate-in animate-show'>
        <span></span><span>Case Studies</span>
      </span>
                <div className="case-studie-text-wrapper">
                    <p>I develop websites for purpose with an <strong>emphasis on increasing user
                        engagement</strong> and generating <strong>positive ROI</strong>.<br/><br/> Browse through a
                        small snapshot of my previous <strong>work</strong> below.</p>
                </div>
                <div className="cases-wrapper animate-in">
                    <NumberItem
                        number="01"
                        left="0"
                        margin="0px 0px 0px 55px"
                        top="85px"
                    />
                    {
                        articles.map(obj =>


                                <div key={obj.id} style={{backgroundImage: `url('${obj.image_main}')`}}>
                                    <Link to={`/work/${obj.id}`} title={obj.name} aria-label={obj.exerpt}>
                                        <span className="filter"></span>
                                        <span className="init-filter"></span>
                                        <span className="filter-hover"></span>

                                        <span>
                          <p className="date">{obj.date}</p>
                          <h5>{obj.name}</h5>
                                            {obj.collaboration ? <p className="collab">{obj.collaboration}</p> : ''}
                                            <span><ReactMarkdown source={obj.exerpt}/></span>
                       </span>
                                    </Link>
                                </div>
                        )
                    }
                </div>

            </div>
        );
    }
}


