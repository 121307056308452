/*
 About Component
*/


import React, { Component } from 'react'; 
import TransitionHeader from './../TransitionHeader'; 

export default class AboutComponent extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };



  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const header = document.getElementById('header-view');
    const hamburger = document.getElementById('menu-button');

    header.classList.remove('header-view-show');
    hamburger.classList.remove('is-active');

  }


  render(){

    const {
      callActionInfo
    } = this.props;



  return (
     <div className="about-component-wrapper">
     
        <TransitionHeader
          title="About"
          backgroundColor="#dad187"
          minyleft="-22px"
        />
      </div>
    );
  }
}


