/*
Footer Action Navigation Component
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';
import ReactDOM from "react-dom";

import './FooterActionNavigation.scss';




export default class FooterActionNavigation extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

   
    };

   

  }


  render(){

    const {
      backgroundone,
      backgroundtwo,
      backgroundcolorone,
      backgroundcolortwo,
      color,
      borderTop,
      linkone,
      linktwo,
      headingone,
      headingonetext,
      headingtwo,
      headingtwotext,
      borderBottom
     
    } = this.props;




   return (
   
      <div className="footer-navigation-action-wrapper animate-in" style={{borderBottom: borderBottom}}>
        <Link to={linkone} style={{backgroundImage:`url(${backgroundone})`}}>
          <span className="filter" style={{backgroundColor:backgroundcolorone}}></span>
          <span className="filter-hover" style={{backgroundColor:backgroundcolortwo}}></span>
          <span className="sub-action-content">
            <h5 style={{color:color}}>{headingone}</h5>
            <p style={{color:color,borderTop:borderTop}}>{headingonetext}</p>
           </span>
        </Link>
        <Link to={linktwo} style={{backgroundImage:`url(${backgroundtwo})`}}>
          <span className="filter" style={{backgroundColor:backgroundcolorone}}></span>
          <span className="filter-hover" style={{backgroundColor:backgroundcolortwo}}></span>
          <span className="sub-action-content">
            <h5 style={{color:color}}>{headingtwo}</h5>
            <p style={{color:color,borderTop:borderTop}}>{headingtwotext}</p>
          </span>
            </Link>
      </div>
        
    );
  }
}


