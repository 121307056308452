/*
 Footer
*/


import React, { Component } from 'react';  
import ReactDOM from "react-dom";

import { Link } from 'react-router-dom';

import './Footer.scss';


import linkedinLogo from '../../media/images/linkedin.svg';
import githubLogo from '../../media/images/github-big-logo.svg';



export default class Footer extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

   
    };

   

  }


  render(){

    const {

    backgroundColor
      
    } = this.props;

      const currentYear = new Date().getFullYear();


      return (
   
      <div className="footer-wrapper">
       <div className="sub-wrap-one">
        <ul>
          <li className="footer-heading">Navigation</li>
          <li><Link to='/'>Home</Link></li>
          <li><Link to='/work'>Work</Link></li>
          <li><Link to='/knowledge'>Knowledge</Link></li>
          <li><Link to='/blog'>Blog</Link></li>
          <li><Link to='/contact'>Contact</Link></li>
        </ul>
       </div>
       <div>
        <ul className="sub-wrap-two">
          <li className="footer-heading location">Location</li>
          <li><a href="https://www.google.com/maps/place/Perth+WA/@-32.0397559,115.6813467,10z/data=!3m1!4b1!4m5!3m4!1s0x2a32966cdb47733d:0x304f0b535df55d0!8m2!3d-31.9505269!4d115.8604572" target="_blank" rel="noreferrer">Perth <span style={{fontSize:'12px',marginLeft:'5px'}}>-31.9505° 115.8605°</span><br/>Western Australia</a></li>
          <li className="footer-heading footer-heading-sub">Contact</li>
          <li><a href="mailto:info@grapecake.com">info@grapecake.com</a></li>
          <li className="footer-heading footer-heading-sub">Socials</li>
          <li className="footer-socials"><a href="https://www.linkedin.com/in/mathew-bowyer-5ba938126/?originalSubdomain=au" rel="noopener" style={{fontSize:"25px"}} target="_blank"><img src={linkedinLogo}/></a></li>
          <li className="footer-socials"><a href="https://github.com/sosroInSpace" rel="noopener" style={{fontSize:"25px"}} target="_blank"><img src={githubLogo}/></a></li>
           
           
        </ul>

       </div>
         <div className="copyright-row">
         <ul>
          <li>grapeCake © {currentYear} All rights reserved</li>
          <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
          <li><Link to="/privacy-policy">Privacy Policy</Link></li>
          <li><a href="https://chromewebstore.google.com/detail/notesplusplus-a-free-note/mmgdpdodbmledhfldedhoechpchkdhda" target="_blank" rel="noreferrer">Chrome Extensions</a></li>
          
         
         </ul>
       </div>
      </div>

        
    );
  }
}


