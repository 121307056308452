/*
 Blog Component
*/


import React, { Component } from 'react'; 
import {Helmet} from "react-helmet";
import TransitionHeader from './../TransitionHeader'; 

import MinySubHeading from '../MinySubHeading';
import ContactAction from './../ContactAction';
import FooterActionNavigation from './../FooterActionNavigation';
import Blogs from './../Blogs';

import octopusOne from '../../media/images/the-octopus.jpeg';
import octopusTwo from '../../media/images/octopus-blue.jpeg';

export default class BlogComponent extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };



  }

  componentDidMount() {
    window.scrollTo(0, 0);

  }


  render(){

 


  return (
     <div className="blog-component-wrapper">

      <Helmet>
        <title>Keep up to date with current digital trends and more. - GRAPECAKE®</title>
        <meta name="description" content="grapeCake Academy - Keep up to date with current digital trends and more."/>
        <meta charSet="utf-8" />
        <meta property="og:url" content="https://grapecake.com/blog" />
        <meta property="og:title" content="Keep up to date with current digital trends and more. - GRAPECAKE®" />
        <meta property="og:description" content="Keep up to date with current digital trends and more." />
        <meta property="og:image" content="/static/media/octopus-blue.920137de.jpeg" />
        <link rel="canonical" href="https://grapecake.com/blog" />
      </Helmet>

        <TransitionHeader
          title="GrapeCake Academy"
          marginBottom="38px"
          subtitle="Keep up to date with current digital trends and more."
          backgroundTitle="Blog"
          minySubHeading="The Blog"
          minyleft="-64px"
          minySubPosition="relative"


        />

        <Blogs/>

         <FooterActionNavigation 
          backgroundone={octopusOne}
          backgroundtwo={octopusTwo}
          linkone="/work"
          headingone="Work"
          headingonetext="Through creative solutions I take ideas and make them tangible."
          linktwo="/knowledge"
          headingtwo="Knowledge"
          headingtwotext="Crafting digital experiences to help brands grow."
        />
        <ContactAction />


        
      </div>
    );
  }
}


