/*
 Transition Header Component
*/


import React, { Component } from 'react';  

import './TransitionHeader.scss';

export default class TransitionHeader extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };



  }
  componentDidMount() {
    const header_text = document.getElementById('transition-title');
    const transition_sub = document.getElementById('transition-sub');

    header_text.innerHTML = header_text.innerHTML.replace(/./g, "<span class='animated-text'>$&</span>").replace(/\s/g, " ");
    transition_sub.innerHTML = transition_sub.innerHTML.replace(/./g, "<span class='animated-text-para'>$&</span>").replace(/\s/g, " ");
  }


  render(){

    const {

      title,
      backgroundColor,
      subtitle,
      titlecolor,
      subcolor,
      backgroundTitle,
      marginBottom,
      minySubPosition,
      minySubMargin,
      minySubHeading,
      minyleft,
      subMarginLeft,
      subtop
      
    } = this.props;



  return (
     <div className="transition-header-wrapper" style={{backgroundColor:backgroundColor}}>
     <div className="transition-wrapper">
        
        </div>
        <div className="transition-content-wrapper">
        <span className="background-title">{backgroundTitle}</span>
          <span className='sub-page-miny-sub-heading-wrapper' style={{position:minySubPosition,margin:minySubMargin,left:minyleft}}>
            <span></span><span>{minySubHeading}</span>
          </span>
          <h2 style={{color:titlecolor,marginBottom:marginBottom}}>
             <span className="title-in">
                <span id="transition-title">{title}</span>
             </span>
          </h2>
          {subtitle ? <p style={{color:subcolor,top:subtop,marginLeft:subMarginLeft}}><span className="title-in"><span id="transition-sub">{subtitle}</span></span></p> : ''}
        </div>
      </div>
    );
  }
}


