/*
  articles.js
*/

export default [

    {
        "id": "mesig",
        "name": "mesig",
        "description": "We provide a central portal for creating, designing and distributing email signature generators on the fly!",
        "keywords": "We provide a central portal for creating, designing and distributing email signature generators on the fly!",
        "image_main": "https://digital-assets-web.s3.amazonaws.com/Screenshot+2023-07-29+at+10.45.59+pm.png",
        "date": "05/06/2023",
        "backgroundSize": "40%",
        "backgroundColor": "#353434",
        "backgroundPosition": "99% 50%",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "mesig",
        "exerpt": "Centralized custom email signature creation.",
        "markdown": "<h3>Client</h3><p></p><a href='https://mesig.io/' target='_blank' rel='noreerrer'>https://mesig.io/</a><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-medium'>76</p></span><span><h5>Accessibility</h5><p class='score-good'>90</p></span><span><h5>Best Practices</h5><p class='score-medium'>75</p></span><span><h5>SEO</h5><p class='score-medium'>67</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Web Services EC2, Heroku, Mongo DB, HTML5, SCSS, Custom Wordpress theme.</li></ul><p><a href='https://mesig.io/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "transforming-families",
        "name": "Transforming Families",
        "description": "WA supportive, safe space for families",
        "keywords": "A supportive, safe space for families",
        "image_main": "https://digital-assets-web.s3.amazonaws.com/tki-fam.png",
        "backgroundSize": "40%",
        "backgroundColor": "#d7d7f8",
        "backgroundPosition": "99% 50%",
        "date": "05/05/2023",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Transforming Families",
        "exerpt": "Creating safe, supportive spaces for families.",
        "markdown": "<h3>Client</h3><p></p><a href='https://transformingfamilies.org.au/' target='_blank' rel='noreerrer'>https://transformingfamilies.org.au/</a><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>92</p></span><span><h5>Accessibility</h5><p class='score-good'>96</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Web Services EC2, HTML5, SCSS, Custom Wordpress theme.</li></ul><p><a href='https://transformingfamilies.org.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },

    {
        "id": "goldfield-oasis",
        "name": "Gold Field Oasis",
        "description": "Whether you want to swim, stay fit or just have a great time, you can do it all at the Goldfields Oasis.",
        "keywords": "Whether you want to swim, stay fit or just have a great time, you can do it all at the Goldfields Oasis's",
        "image_main": "https://goldfieldsoasis.com.au/wp-content/uploads/2021/09/photo-1515651513386-89be725f1571.jpeg",
        "date": "05/02/2023",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Goldfields Oasis",
        "exerpt": "24/7 award-winning gym and aquatic complex.",
        "markdown": "<h3>Client</h3><p></p><a href='https://goldfieldsoasis.com.au/' target='_blank' rel='noreerrer'>https://goldfieldsoasis.com.au/</a><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>94</p></span><span><h5>Accessibility</h5><p class='score-good'>94</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>92</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Web Services EC2, HTML5, SCSS, Custom Wordpress theme.</li></ul><p><a href='https://goldfieldsoasis.com.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "femma-case-study",
        "name": "Femma",
        "description": "Start your journey to better health in the comfort of your own home with Femma’s clinically lead platform and supportive community.",
        "keywords": "web development, custom website, wordpress, woocommerce",
        "image_main": "https://www.femma.com.au/wp-content/uploads/2021/12/explore_72013b02-4d38-4ddd-8caa-279e372aaadb_3024x-1-4.jpg",
        "date": "05/11/2021",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Femma",
        "exerpt": "Start your health journey with Femma's expert platform.",
        "markdown": "<h3>Client</h3><p><a href='https://www.femma.com.au/' target='_blank' rel='noreerrer'>https://www.femma.com.au/</a></p><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>95</p></span><span><h5>Accessibility</h5><p class='score-good'>93</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Ec2, Wordpress</li></ul><p><a href='https://www.femma.com.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "pilbara-minerals-study",
        "name": "Pilbara Minerals",
        "description": "Engergise your future. The future is lithium. The future is bright. Make it yours.",
        "keywords": "web development, custom website, wordpress, woocommerce",
        "image_main": "https://pilbaraminerals.com.au/wp-content/uploads/2023/09/090523_csfoto_0487.jpg",
        "date": "06/11/2021",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Pilbara Minerals",
        "exerpt": "Energize your future with lithium.",
        "markdown": "<h3>Client</h3><p><a href='https://pilbaraminerals.com.au/' target='_blank' rel='noreerrer'>https://pilbaraminerals.com.au/</a></p><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>95</p></span><span><h5>Accessibility</h5><p class='score-good'>93</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Ec2, Wordpress</li></ul><p><a href='https://pilbaraminerals.com.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "the-fast-800-case-study",
        "name": "The Fast 800",
        "description": "Michael Mosley's weight loss programme for better health",
        "keywords": "web development, custom website, wordpress, woocommerce",
        "image_main": "https://thefast800.com/wp-content/uploads/2021/06/P9-10_Dukkah_c-1024x570.jpg",
        "date": "05/11/2021",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Fast800",
        "exerpt": "Michael Mosley's weight loss program.",
        "markdown": "<h3>Client</h3><p><a href='https://thefast800.com/' target='_blank' rel='noreerrer'>https://thefast800.com</a></p><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>95</p></span><span><h5>Accessibility</h5><p class='score-good'>93</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Ec2, Custom Woocommerce, Wordpress</li></ul><p><a href='https://thefast800.com/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "ejbmwb",
        "name": "EJP | MWB",
        "description": "EJP | MWB is a single page application built on next.js",
        "keywords": "Digital, web development, react.js development",
        "image_main": "https://digital-assets-web.s3.amazonaws.com/ejp-mwb.png",
        "date": "13/05/2024",
        "exerpt": "Elegant wedding site with photos and key details.",
        "hover-title": "EJB | MWB",
        "markdown": "<a href='https://wedding-two-vert.vercel.app/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "charleswaymark",
        "name": "Charles Waymark",
        "description": "Charles Waymark 1 page budget static website",
        "keywords": "Digital, web development, html, css development",
        "image_main": "https://digital-assets-web.s3.amazonaws.com/charlea-waymark.png",
        "date": "10/05/2024",
        "exerpt": "Elegant wedding site with photos and key details.",
        "hover-title": "Charles Waymark",
        "markdown": "<a href='https://charleswaymark.com/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "okmg",
        "name": "OKMG",
        "description": "OKMG is a next generation content marketing agency that combines strategic thinking with high impact creative execution and integrated digital solutions.",
        "keywords": "Digital, web development, react.js development",
        "image_main": "https://images.unsplash.com/photo-1552083974-186346191183?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
        "date": "29/12/2022",
        "collaboration": "Built whilst at OKMG",
        "exerpt": "Born creative. Raised digital.",
        "hover-title": "OKMG",
        "markdown": "<h3>Client</h3><p></p><a href='https://okmg.com/' target='_blank' rel='noreerrer'>https://okmg.com</a><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>92</p></span><span><h5>Accessibility</h5><p class='score-good'>96</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Web Services S3, Cloudfront, react.js, HTML5, SCSS, Create React App.</li></ul><p><a href='https://okmg.com' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "dancing-ledge-study",
        "name": "Dancing Ledge",
        "description": "See the world differently. We identify, create and actively invest to build innovative and sustainable businesses in the health sector.",
        "keywords": "web development, custom website, wordpress, woocommerce",
        "image_main": "https://images.unsplash.com/photo-1552083974-186346191183?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2670&q=80",
        "date": "06/11/2022",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Dancing Ledge",
        "exerpt": "Build innovative, sustainable health sector businesses through investment and creation.",
        "markdown": "<h3>Client</h3><p><a href='https://www.dancingledge.com.au//' target='_blank' rel='noreerrer'>https://www.dancingledge.com.au//</a></p><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>95</p></span><span><h5>Accessibility</h5><p class='score-good'>93</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Ec2, Custom Wordpress</li></ul><p><a href='https://ronfarrisrealestate.com.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "dimattina-coffee-study",
        "name": "Dimattina Coffee",
        "description": "Coffee worth getting up for",
        "keywords": "web development, custom website, shopify, woocommerce",
        "image_main": "https://dimattinacoffee.com.au/cdn/shop/files/Untitled_design_16_x1920.png?v=1663657060",
        "date": "06/11/2021",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Dimattina Coffee",
        "exerpt": "Discover your perfect coffee.",
        "markdown": "<h3>Client</h3><p><a href='https://dimattinacoffee.com.au/' target='_blank' rel='noreerrer'>https://dimattinacoffee.com.au//</a></p><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>95</p></span><span><h5>Accessibility</h5><p class='score-good'>93</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Shopify, Custom Shopify Theme</li></ul><p><a href='https://ronfarrisrealestate.com.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "ron-farris-study",
        "name": "Ron Farris Real Estate",
        "description": "Ron Farris Real Estate is fortunate to have 40+ years of commercial real estate experience and knowledge that fuels our company.",
        "keywords": "web development, custom website, wordpress, woocommerce",
        "image_main": "https://ronfarrisrealestate.com.au/wp-content/uploads/2020/12/commercial-hero.png",
        "date": "06/11/2021",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Ron Farris Realestate",
        "exerpt": "40+ years of commercial real estate fuels our company.",
        "markdown": "<h3>Client</h3><p><a href='https://ronfarrisrealestate.com.au/' target='_blank' rel='noreerrer'>https://ronfarrisrealestate.com.au/</a></p><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>95</p></span><span><h5>Accessibility</h5><p class='score-good'>93</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Ec2, Custom Wordpress, Aus Property API integration</li></ul><p><a href='https://ronfarrisrealestate.com.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "twin-fin-case-study",
        "name": "Twin Fin",
        "description": "Twin Fin is a fast casual fish and chips shop that breaks the rules - but doesn't steer to far from tradition.",
        "keywords": "marketing operations, overlooked marketing operations, largest overlooked marketing operations",
        "image_main": "https://images.unsplash.com/photo-1583815950467-e7b26a5b56b3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=774&q=80",
        "date": "05/11/2019",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Twin Fin",
        "exerpt": "Fast casual fish and chips with a twist.",
        "markdown": "<h3>Client</h3><p><a href='https://twinfin.com.au' target='_blank' rel='noreerrer'>https://twinfin.com.au></a></p><h3>Challenge</h3><p>A new restaurant was opening and needed a new website to reflect their brand identity.</p><h3>Outcome</h3><p> A fast and largely optimised micro site was built using the latest web technologies which in turn helped the Twin Fin brand  come across as a niche and high value product.</p><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>95</p></span><span><h5>Accessibility</h5><p class='score-good'>93</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Web Services s3, HTML5, SCSS, React.js, NPM /li&gt;</li></ul><p><a href='https://www.twinfin.com.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },


    {
        "id": "revo-fitness-case-study",
        "name": "Revo",
        "description": "The rebranded website increased user awareness and in conjunction with the new member sign up process increasinly grows Revo Fitness to becoming one of the most sought after gyms in Western Australia.",
        "keywords": "Case Study, work , fitness, revo, revo fitness, gym, sport",
        "image_main": "https://digital-assets-web.s3.amazonaws.com/revo-screengrab.webp",
        "date": "04/02/2019",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Revo Fitness",
        "exerpt": "Creating the quickest fitnesss membership on the market.",
        "markdown": "<h3>Client</h3><p><a href='https://revofitness.com.au' target='_blank' rel='noreerrer'>https://revofitness.com.au></a></p><h3>Challenge</h3><p>Their rapid growth meant that the Revo Fitness brand messaging had quickly become outdated and no longer connected with customers. </p><p>The key challenge was establishing a refreshed brand identity that didn’t isolate existing members whilst better reflecting the maturity and confidence of Revo Fitness.</p><h3>Solution</h3><p>A new website needed to be built to reflect their new identity and increase their conversion rate with the fastest sign up process available for wood-be gym goers.</p><h3>Outcome</h3><p>The re-branded website increased user awareness and in conjunction with the new member sign up process increasinly grows Revo Fitness to becoming one of the most sought after gyms in Western Australia.</p> <h3>Technologies</h3><ul class='sub-quote'><li>Custom built Wordpress CMS</li><li>Amazon Web Services - Hosting, SMTP, SSL</li><li>API integration - Payment gateway </li><li>API integration - Customer Relationship Management</li></ul><p><a href='https://revofitness.com.au' target='_blank' rel='noreferrer'>View Website</a></p>"
    },
    {
        "id": "mesim",
        "name": "mesim",
        "description": "Always stay connected whereever you go",
        "keywords": "digital sim cards, esim, sim card",
        "image_main": "https://digital-assets-web.s3.amazonaws.com/mesim-card-2.96d887a5cba61bf08332.png",
        "date": "01/04/2023",
        "hover-title": "Mesim",
        "exerpt": "Cost-saving digital sim cards with 4G LTE.",
        "markdown": "<h3>Client</h3><p></p><a href='https://mesim.io/' target='_blank' rel='noreerrer'>https://mesim.io/</a><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>90</p></span><span><h5>Accessibility</h5><p class='score-good'>90</p></span><span><h5>Best Practices</h5><p class='score-good'>92</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Web Services EC2, AWS S3, AWS Cloudfront, AWS Lambda, AWS SNS, AWS SES, React.js, Material UI</li></ul><p><a href='https://mesim.io/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },
    {
        "id": "howjsay-case-study",
        "name": "Howjsay",
        "description": "An investment analysis for you to read before investing in Digital Asset Management Platform.",
        "keywords": "Why DAM?, why dam?, business case ",
        "image_main": "https://images.unsplash.com/photo-1470790376778-a9fbc86d70e2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=698&q=80",
        "date": "04/02/2019",
        "hover-title": "Howjsay",
        "exerpt": "Supplying the world with free english pronunciation.",
        "markdown": "<h3>Client</h3><p><a href='https://howjsay.com' target='_blank' rel='noreerrer'>https://howjsay.com.au></a></p><h3>Challenge</h3><p>Howjsay's platform was initially built on FLASH.</p><p><a href='https://www.google.com/search?ei=_ddPXZHkLNDiz7sPgbiOkAw&q=support+for++flash&oq=support+for++flash&gs_l=psy-ab.3..0i71l8.26746.26879..27087...0.0..0.216.216.2-1......0....1..gws-wiz.hdpKEbGQPYc&ved=0ahUKEwjR1o2MufrjAhVQ8XMBHQGcA8IQ4dUDCAo&uact=5' target='_blank' rel='noreferrer'>Support for FLASH</a> has increasingly diminished and most modern browsers no longer carry support.</p><p> This issue resulted in the loss of revenue and a loyal customer base. </p><h3>Solution</h3><p>A new website needed to be built quickly with an emphasis on speed and cross browser reliability.</p><p> Howjsay's out-dated interface also needed to be re-built into something that would fit into the modern world.</p><h3>Outcome</h3><p>Please view some of Howjsay's metrics since launch below: </p><ul><li>User count: <span class='traffic-score'>↑4,185.4%<span></li><li>Sessions: <span class='traffic-score'>↑5,243.4%</span></li><li>Bounce Rate: <span class='traffic-score'>↓2.3%</span></li><li>Session Duration: <span class='traffic-score'>↑3.2%</span></li></ul><h3>Audit</h3><span class='scores-wrapper animate-in'><span><h5>Performance</h5><p class='score-good'>100</p></span><span><h5>Accessibility</h5><p class='score-good'>94</p></span><span><h5>Best Practices</h5><p class='score-medium'>79</p></span><span><h5>SEO</h5><p class='score-good'>94</p></span></span><h3>Technologies used:</h3><ul class='sub-quote'><li>LAMP STACK</li></ul><p><a href='https://howjsay.com' target='_blank' rel='noreferrer'>View Website</a></p>"
    },
    {
        "id": "lever-case-study",
        "name": "Lever",
        "description": "An investment analysis for you to read before investing in Digital Asset Management Platform.",
        "keywords": "Why DAM?, why dam?, business case ",
        "image_main": "https://images.unsplash.com/photo-1531591022136-eb8b0da1e6d0?auto=format&fit=crop&ixid=eyJhcHBfaWQiOjEyMDd9&ixlib=rb-1.2.1&q=80&w=1300",
        "date": "04/02/2019",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "Lever Property",
        "exerpt": "Better commercial & residential leasing management.",
        "markdown": "<h3>Client</h3><p><a href='https://leverproperty.com.au' target='_blank' rel='noreerrer'>https://leverproperty.com.au></a></p><h3>Challenge</h3><p>Creating a website which streamlines and consolidates Levers' content management CRM.</p><p>The key challenge was establishing a refreshed brand identity and streamlining levers' CRM into their website.</p><h3>Solution</h3><p>A new website needed to be built to reflect their new identity and automate levers' property crm into a publically branded portal.</p><h3>Outcome</h3><p>The re-branded website increased user awareness and allowed the automation of their property management CRM to feed into their the new website</p> <h3>Technologies</h3><ul class='sub-quote'><li>Custom built Wordpress CMS</li><li>Amazon Web Services - Ec2 Hosting, SMTP, SSL</li><li>API integration - VaultRe </li></ul><p><a href='https://leverproperty.com.au' target='_blank' rel='noreferrer'>View Website</a></p></div>"
    },
    {
        "id": "distinguished-homes-case-study",
        "name": "DH Homes",
        "description": "One of Perth's trusted luxury home builders. Whatever your style and budget we will work closely with you.",
        "keywords": "marketing operations, overlooked marketing operations, largest overlooked marketing operations",
        "image_main": "https://www.distinguishedhomes.com.au/wp-content/uploads/dh-house.jpg",
        "date": "05/10/2019",
        "hover-title": "Distinguished Homes",
        "exerpt": "Trusted luxury home builder in Perth.",
        "markdown": "<h3>Client</h3><p><a href='https://distinguishedhomes.com.au' target='_blank' rel='noreerrer'>https://distinguishedhomes.com.au</a></p><h3>Challenge</h3><p>Distinguished Homes' existing website was outdated and need to be re-built to reflectt their new brand identitiyw with the functioanlity of a scalable property plan and construction portal.</p><h3>Outcome</h3><p> The new website was built which included a scalable solution to adding additional properties and plans into their company website, advanced form tracking and a design inline with their current re-branded company assets.</p> <h3>Technologies</h3><ul class='sub-quote'><li>Custom Wordpress CMC, Advanced custom fields, HTML5, SCSSS, Jquery, GULP, NPM</li></ul><p><a href='https://www.distinguishedhomes.com.au/' target='_blank' rel='noreferrer'>View Website</a></p>"
    },

    {
        "id": "gamedex-case-study",
        "name": "Gamedex",
        "description": "A closer examination of potential choke-points within a major launch suggests how automation might provide time- to-market gains. ",
        "keywords": "marketing operations, overlooked marketing operations, largest overlooked marketing operations",
        "image_main": "https://images.unsplash.com/photo-1478358161113-b0e11994a36b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=668&q=80",
        "date": "05/05/2019",
        "hover-title": "Gamedex",
        "exerpt": "Digital collectible cards with blockchain.",
        "markdown": "<h3>Client</h3><p>Gamedex - Digital collectible cards powered by blockchain</p><h3>Challenge</h3><p>Gamedex needed additional components to their exisiting websites UI.</p><h3>Outcome</h3><p> Additional Components and style changes were made to the website to allow visiting users greater access to Gamedexs' story and important information which in turn allowed an increase in investor enquires.</p> <h3>Technologies</h3><ul class='sub-quote'><li>HTML5, SCSSS, JavaScript</li></ul><p><a href='https://www.gamedex.co/' target='_blank' rel='noreferrer'>View Website</a></p>"
    },

    {
        "id": "hair-supplies-case-study",
        "name": "Hair Supplies",
        "description": "Get access to over 14,000 high quality salon products at the best price!.",
        "keywords": "marketing operations, overlooked marketing operations, largest overlooked marketing operations",
        "image_main": "https://cdn.shopify.com/s/files/1/0026/1560/7341/files/160620-253-web_1512x.jpg?v=1591942489",
        "date": "05/11/2019",
        "hover-title": "Hair Supplies",
        "collaboration": "Built whilst at OKMG",
        "exerpt": "14,000+ high-quality salon products.",
        "markdown": "<h3>Client</h3><p><a href='https://hairsupplies.com.au' target='_blank' rel='noreerrer'>https://hairsupplies.com.au></a></p><h3>Challenge</h3><p>A new restaurant was opening and needed a new website to reflect their brand identity.</p><h3>Outcome</h3><p> A fast and largely optimised micro site was built using the latest web technologies which in turn helped the Twin Fin brand  come across as a niche and high value product.</p><h3>Audit</h3><span class='scores-wrapper animate-in animate-show'><span><h5>Performance</h5><p class='score-good'>95</p></span><span><h5>Accessibility</h5><p class='score-good'>93</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>Hosting - Amazong Web Services s3, HTML5, SCSS, React.js, NPM /li&gt;</li></ul><p><a href='https://www.twinfin.com.au/' target='_blank' rel='noreferrer'>View Website</a></p> <div class='prev-next-article-footer'></div>"
    },


    {
        "id": "hivo-case-study",
        "name": "HIVO",
        "description": "How can you save money by automating common marketing, production,sales and training? Here are some solid tips.",
        "keywords": "automation, money, save, marketing",
        "image_main": "https://images.unsplash.com/photo-1557672172-298e090bd0f1?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=387&q=80g",
        "date": "10/05/2019",
        "collaboration": "Built whilst at OKMG",
        "hover-title": "HIVO",
        "exerpt": "Conveying a fast and reliable DAM system to the masses.",
        "markdown": "<h3>Client</h3><p><a href='https://hivo.co' target='_blank' rel='noreerrer'>https://hivo.co></a></p><h3>Challenge</h3><p>Creating a website which conveys the <a href='https://web.archive.org/web/20200331014541/https://hivo.com.au' target='_blank' rel='noreferrer'>HIVO</a> product as a fast and reliable digital asset management system.</p><h3>Solution</h3><p>A new website needed to be built to reflect their new identity with an emphasis on search engine optimisation(SEO) and an increase in the reflection of a high-end consumer product.</p><h3>Outcome</h3><p>The re-branded website increased user engagement and signup enquires generating a positive ROI and an ever increasing Google results ranking. </p><h3>Audit</h3><span class='scores-wrapper animate-in'><span><h5>Performance</h5><p class='score-good'>92</p></span><span><h5>Accessibility</h5><p class='score-good'>92</p></span><span><h5>Best Practices</h5><p class='score-good'>100</p></span><span><h5>SEO</h5><p class='score-good'>100</p></span></span> <h3>Technologies</h3><ul class='sub-quote'><li>React.js</li><li>Amazon Web Services - Static s3 Hosting, SMTP, SSL</li></ul><p><a href='https://hivo.com.au' target='_blank' rel='noreferrer'>View Website</a></p>"
    },

    {
        "id": "seagate-case-study",
        "name": "Seagate",
        "description": "Senior management should consider any digital file (or digitized image) that a field-marketing executive would appreciate seeing or using as a good candidate for storing in a digital asset repository.",
        "keywords": "files, Files, files to put into repository, repository",
        "image_main": "https://www.seagate.com//files/www-content/product-content/internal-hard-drives/_shared/images/top-header-1920x670.jpg",
        "date": "04/02/2019",
        "collaboration": "Built whilst at Kudoz Agency",
        "hover-title": "Seagate",
        "exerpt": "Expanding Seagate in Southeast Asia.",
        "markdown": "<h3>Client</h3><p>Seagate Thailand</p><h3>Challenge</h3><p>Seagate Thailand needed to increase their user presense within South East Asia.</p><h3>Solution</h3><p>A micro site reflecting the Seagate brand with the functionaility of running a photography compeition.</p><h3>Outcome</h3><p>Allong side the digital marketing competition campaign and the new website Seagate Technogolies found an increase of user awareness through out Thailand. </p> <h3>Technologies</h3><ul class='sub-quote'><li>LAMP Stack</li></ul><p><a href='https://perfectshot.seagatethailand.com/' target='_blank' rel='noreferrer'>View Website</a></p>"
    },


];
