/*
  SingleBlogs.jsx
*/

import React, { Component } from 'react';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

// Articles.
import articles from '../../articles/articles.js';

// Components.
import NotFoundComponent from '../NotFoundComponent';

// Styles.
import './SingleWork.scss';

/*
  Base component.
*/




export default class SingleWork extends React.Component {

  constructor(props) {
    super(props);

    this.state = {

      status: false

    };
  }


  componentDidMount() {

   
    window.scrollTo(0, 0);


  }

  scrollTopLink() {

    window.scrollTo(0, 0);
    
    this.setState({status: !this.state.status});


  }


  scrollMoreDown = () => {

    let windowHeight = window.innerHeight;
    windowHeight = windowHeight + 50;
    window.scrollTo({
        top: windowHeight,
        left: 100,
        behavior: 'smooth'
      });

    }



  render() {

    const { match, moreBackground, moreColor, moreLineColor  } = this.props;

    const workId = match.params.workId;
    const blogIndex = articles.findIndex(obj => obj.id === workId);

    if (blogIndex < 0) {
      return <NotFoundComponent/>;
    }

    const blog = articles[blogIndex];
    const prevBlog = articles[((blogIndex - 1) + articles.length) % articles.length];
    const nextBlog = articles[(blogIndex + 1) % articles.length];

    const prev = " PREVIOUS";
    const next = "NEXT ";


   const articleBackground = {
      backgroundImage: `url(${blog.image})`,

    };



    return (
      <div className="single-work-page-wrapper animate-in">

       <Helmet>
        <title>{blog.name} - GRAPECAKE®</title>
        <meta name="description" content={blog.exerpt}/>
        <meta charSet="utf-8" />
        <meta property="og:url" content={'https://grapecake.com/blog/' + blog.id} />
        <meta property="og:title" content={blog.name + '- GRAPECAKE®'}  />
        <meta property="og:description" content={blog.exerpt} />
        <meta property="og:image" content={blog.image} />
        <link rel="canonical" href={'https://grapecake.com/blog/' + blog.id} />
      </Helmet>

       <div className={this.state.status ? 'entry-text article-on' : 'entry-text article-off'}>
           <div className={this.state.status ? 'article-on' : 'article-off'}>
            <h1>{blog.name}</h1>
            <h3 className="exerpt">{blog.exerpt}</h3>
               <ul className="blog-author-info">
                <li>{blog.headshot ? <img src={blog.headshot} alt="headshot" className="author-headshot"/> : ''}</li>
                <li><span className="blog-date">{blog.date}</span></li>
              </ul>
              
          
           
            </div>
             <div className="view-more" id="view-more-scroller-top" style={{background:moreBackground}} onClick={this.scrollMoreDown.bind(this)}>
              <div className="span-more" style={{color:moreColor}}><div></div> <div><span><span style={{background:moreLineColor}}></span></span></div></div>
            </div>
            <span className="animate-line"></span>
          </div>
          <div className="blog-header-image" style={{backgroundImage:`url(${blog.image_main})`}}>
            <span className="blog-header-filter"></span>
          </div>
          <div className="single-work-body-content">
           {blog.collaboration ? <span className="collaboration">{blog.collaboration}</span> : ''}
          {ReactHtmlParser(blog.markdown)}

           
            <div className="prev-next-article-footer">
              <Link to={'/work/' + prevBlog.id} onClick={this.scrollTopLink.bind(this)}>{prev}</Link> 
              <Link to={'/work/' + nextBlog.id} onClick={this.scrollTopLink.bind(this)}>{next} </Link>
            </div>
          </div>
       
      </div>
    );
  }
}
