/*
  Contact Call to Action Component.jsx
*/


import React, { Component } from 'react';  
import { Link } from 'react-router-dom';

import './HeaderComponent.scss';

//icons 
import facebookLogo from '../../media/images/linkedin-logo-dark.svg';
import instagramLogo from '../../media/images/github-sign-dark.svg';
import deleteIcon from '../../media/images/delete.svg';
// style variables
import variables from '../../config_var/config_var.js';

const webLinks = [
{
  heading: 'How it works',
  link: '',
  image: ''
},
{
  heading: 'Services',
  link: '',
  image: ''
},
{
  heading: 'Work',
  link: '',
  image: ''
},
{
  heading: 'Blog',
  link: '',
  image: ''
},
{
  heading: 'Contact',
  link: '',
  image: ''
}

];

export default class HeaderComponent extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {
      
      isToggleOn: false,
      modalShow: false,
      isMenuOn: '',
      linkColor: '',
      
   
    };

     this.menuFunction = this.menuFunction.bind(this);
     this.menuLinkFunction = this.menuLinkFunction.bind(this);
     this.menuLinkFunctionOff = this.menuLinkFunctionOff.bind(this);
     this.meneBurgerOff = this.meneBurgerOff.bind(this);
     this.closeModal = this.closeModal.bind(this);
  }


  menuFunction() {

    this.setState({isToggleOn: !this.state.isToggleOn});

  }

  closeModal(){
    this.setState({modalShow: true});
 }


  menuLinkFunction(event){

    let linkValue = event.currentTarget.innerText;

    this.setState({isMenuOn: linkValue + ' header-view-sub header-view-bottom'})
    this.setState({linkColor: linkValue + ' link-on'})

  }

  menuLinkFunctionOff(){


    this.setState({isMenuOn: ''});
    this.setState({linkColor: ''});
   

  }

  meneBurgerOff(){
     this.setState({isToggleOn: false});
  }

 

  render(){

    const {
      
    } = this.props;


    const modalClass = this.state.modalShow ? 'modal modal-off' : 'modal modal-on';

    
    var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 4000, // Set the duration between each scroll (in milliseconds)
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1
  };

  return (


     <div className="header-component-wrapper" style={{position:'fixed'}}>
     <div 
     class="modal modal-off"
     onClick={this.closeModal}
     >
     <img src={deleteIcon} alt="x"/>
     <span>
      <p>Currrently only open for projects through <a href="https:okmg.com" target="_blank" rel="norefferer">OKMG</a></p>
     </span>
     </div>
        <div className="header-navigation">
        <div className="header-navigation-sub" style={{fontWeight:'900',fontFamily:'Roboto,arial',fontSize:'34px',margin:'6px 11px',color:'wheat'}}>
          <Link to="/" className={this.state.isToggleOn ? 'grape-active' : 'grape-non-active'}>grapeCake<span className="blink_dot">.</span></Link>
        </div>
          <div className="header-navigation-sub">
          
            <button 
            className={this.state.isToggleOn ? 'hamburger hamburger--minus is-active' : 'hamburger hamburger--minus'}
            type="button" 
            id="menu-button" 
            onClick={this.menuFunction}


            >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
              </span>
            </button>
          </div>
        </div>
        <div className={this.state.isToggleOn ? 'header-view header-view-show' : 'header-view'} id="header-view">
          <div className="header-view-sub header-view-left">
            <span>
              <ul>
                <li className="header-view-heading">
                  Navigation
                </li>
                 <li>
                  <Link 
                  to="/"
                  onClick={this.meneBurgerOff} 
                  onMouseEnter={this.menuLinkFunction} 
                  onMouseLeave={this.menuLinkFunctionOff}
                  className={this.state.linkColor ? this.state.linkColor : ''}
                  >
                   Home
                  </Link>
                </li>
                 <li className="link-list">
                  <Link 
                  to="/work" 
                  onClick={this.meneBurgerOff} 
                  onMouseEnter={this.menuLinkFunction} 
                  onMouseLeave={this.menuLinkFunctionOff}
                  className={this.state.linkColor ? this.state.linkColor : ''}
                  >
                  Work
                  </Link>
                </li>
                <li className="link-list">
                  <Link 
                  to="/knowledge" 
                  onClick={this.meneBurgerOff}
                  onMouseEnter={this.menuLinkFunction} 
                  onMouseLeave={this.menuLinkFunctionOff}
                  className={this.state.linkColor ? this.state.linkColor : ''}
                  >
                  Knowledge
                  </Link>
                </li>
                <li className="link-list">
                  <Link 
                  to="/blog" 
                  onClick={this.meneBurgerOff}
                  onMouseEnter={this.menuLinkFunction} 
                  onMouseLeave={this.menuLinkFunctionOff}
                  className={this.state.linkColor ? this.state.linkColor : ''}
                  >
                   Blog
                  </Link>
                </li>
                
                <li className="link-list">
                  <Link 
                  to="/contact" 
                  onClick={this.meneBurgerOff}
                  onMouseEnter={this.menuLinkFunction} 
                  onMouseLeave={this.menuLinkFunctionOff}
                  className={this.state.linkColor ? this.state.linkColor : ''}
                  >
                    Contact
                  </Link>
                </li>

              </ul>
            </span>
          </div>
          <div className="header-view-sub header-view-right">
            <span>
              <ul>
                <li className="header-view-heading">Location</li>
                <li>Perth,<span style={{fontSize:'12px',marginLeft:'5px'}}>-31.9505° 115.8605°</span></li>
                <li>Western Australia</li>
              </ul>
              <ul>
                <li className="header-view-heading">Contact</li>
                <li><a href="mailto:info@grapecake.com">info@grapecake.com</a></li>
              </ul>

            </span>
          </div>
          <div className={this.state.isMenuOn ? this.state.isMenuOn : 'header-view-sub header-view-bottom'}>
            <div  className="social-link-list">
              <ul>
                <li style={{marginRight:'10px'}}>Socials</li>
                <li><a href="https://www.linkedin.com/in/mathew-bowyer-5ba938126/?originalSubdomain=au" rel="noopener"><img src={facebookLogo} alt=""/></a></li>
                <li><a href="https://github.com/sosroInSpace" rel="noopener"><img src={instagramLogo} alt=""/></a></li>
              </ul>
              </div>
          </div>
        </div>
        <div class="projects-wrapper">
   
      </div>
      </div>
    );
  }
}


