/*
 Number Item Component
*/


import React, { Component } from 'react';  
import ReactDOM from "react-dom";

import './ContactForm.scss';




export default class ContactForm extends React.Component {



    constructor(props) {

    super(props);

    // State.

    this.state = {

   
    };

   

  }


  render(){

    const {


    } = this.props;




   return (
   
      <div className="contact-form-wrapper" >

      <div className="contact-text-wrapper">
      <p className="contact-intro-text" style={{marginBottom: '5px',lineHeight:'130%'}}>Please get in touch via the email below - you'll receive a response within 24hours</p>
         <p><a href="mailto:info@grapecake.com" style={{fontSize: '40px',marginTop:'25px',display:'inline-block'}}>info@grapecake.com</a></p>
      </div> 
     {/*
        <form className="boilerform animate-in" action="" method="post">
            <fieldset className="c-form">
                <div className="c-form__row">
                  <div>
                    <span class="span-inline"></span>
                    <label for="name" className="c-label span-inline">Name*</label>
                    <input type="text" name="name" id="name" required />
                  </div>
                  <div>
                  <span class="span-inline"></span>
                     <label for="company" className="c-label">Company</label>
                    <input type="text" name="company" id="company"  />
                  </div>
                  
                  <div>
                    <span class="span-inline"></span>
                     <label for="email" className="c-label">E-Mail*</label>
                    <input type="email" name="email" id="email"  required />
                  </div>
                </div>
                <div className="c-form__row">
                   <div>
                    <span class="span-inline"></span>
                    <label for="phone" className="c-label">Phone Number</label>
                    <input type="text" name="phone" id="phone" />
                  </div>
                  <div>
                    <span class="span-inline"></span>
                     <label for="budget" className="c-label">Budget*</label>
                    <input type="text" name="budget" id="budget" required />
                  </div>
                </div>
                <div className="c-form__row">
                    <span class="span-inline"></span>  
                    <label for="message" className="c-label">Project Description*</label>
                    <textarea name="message" id="message" className="c-input-field c-input-field--multiline" rows="10"></textarea>
                </div>
               
                <div className="c-form__row">
                    <button className="c-button" type="submit">Submit</button>    
                </div>
            </fieldset>
        </form>
    */}
      </div>
        
    );
  }
}


