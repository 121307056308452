/*
 Home Component
*/


import React, { Component } from 'react';  
import './NotFoundComponent.scss';
import Lottie from 'react-lottie';
import animationData from './404.json';

export default class NotFoundComponent extends React.Component {



    constructor(props) {
    super(props);

    // State.

    this.state = {

   
    };



  }


  render(){

    const {
      
    } = this.props;

     const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };


  return (
     <div className="not-found-component-wrapper">
      <div className="four-wrap">
        <Lottie options={defaultOptions}
              height='100%'
              width='100%'/>
        </div>
      </div>
    );
  }
}


